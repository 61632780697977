<template>
  <div class="py-6 px-4 sm:p-6 lg:pb-8">
    <div>
      <div class="text-xl leading-6 w-36 font-medium text-gray-900 relative mb-2">
        User Pricing
        <div
          class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip absolute right-0"
          style="top: -5px"
        >
          <font-awesome-icon class="text-lg" icon="info-circle" />
          <span
            class="tooltiptext shadow text-black p-2 flex flex-col w-64 text-left right-0 text-sm"
          >
            If a customer calls and says they are not seeing their special price on the product
            page, it means that the unique identifier for the product has changed. Remove that
            product from their special priced items and add it again.
          </span>
        </div>
      </div>
    </div>
    <div v-if="companyLoading">
      <Loading />
    </div>
    <div v-else class="flex justify-between flex-wrap">
      <div class="w-7/12 mr-2" id="special-priced-products">
        <div class="flex flex-col">
          <div class="shadow-lg border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 table-fixed rounded overflow-hidden">
              <thead class="bg-gray-200">
                <tr class="flex w-full">
                  <th
                    scope="col"
                    class="px-3 py-3 text-left text-xs flex-grow font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3 text-left text-xs flex-grow font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cost
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3 text-left text-xs flex-grow font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th scope="col" class="relative px-3 py-3">
                    <span class="sr-only">Save</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 overflow-auto h-1/2-screen block">
                <tr v-for="variant in company.products" :key="variant._id">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img class="h-10 w-10 rounded-full" :src="variant.image" alt="" />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ variant.product_name }} <span>({{ variant.productNum }})</span>
                        </div>
                        <div class="text-sm text-gray-500">{{ variant.option }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">
                      ${{ variant.cost ? variant.cost.toFixed(2) : variant.cost }}
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap relative">
                    <div
                      class="absolute inset-y-0 pl-4 left-1 flex items-center pointer-events-none"
                    >
                      <span class="text-gray-500"> $ </span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      class="border focus:border-blue-500 block pl-10 pr-12 border-gray-300 rounded-md w-40"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                      step="any"
                      @blur="checkPrice(variant)"
                      v-model="variant.price"
                    />
                    <div
                      class="absolute inset-y-0 pr-3 right-1 flex items-center pointer-events-none"
                    >
                      <span class="text-gray-500" id="price-currency"> USD </span>
                    </div>
                  </td>
                  <td class="px-3 whitespace-nowrap text-right text-sm font-medium">
                    <div class="my-auto">
                      <button
                        class="text-blue-600 hover:text-blue-900 hover:bg-blue-400 rounded px-2 py-1"
                        @click="saveToSpecialPricing(variant)"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        @click="removeVariant(variant.productNum)"
                        class="hover:bg-red-300 cursor-pointer rounded px-2"
                      >
                        <font-awesome-icon icon="times" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="w-4/12 shadow-lg overflow-hidden">
        <input
          type="text"
          class="px-4 w-full py-2 rounded border mb-2"
          :class="variantLoading ? 'bg-gray-300' : ''"
          :placeholder="variantLoading ? 'Loading...' : '+  Part Number'"
          v-model="partNumber"
          :disabled="variantLoading"
          @keyup.esc="partNumber = null"
        />

        <ul
          v-if="!variantLoading"
          class="mt-1 overflow-auto bg-white rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm h-1/2-screen"
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li
            v-for="variant in variants"
            :key="variant._id"
            @click="addToVariants(variant)"
            class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-blue-200 hover:text-white w-full"
            id="listbox-option-0"
            role="option"
          >
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium">
                  {{ variant.product_name }}
                </div>
                <div class="text-sm text-gray-500">{{ variant.productNum }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="my-4 overflow-x-auto overflow-y-hidden relative">
      <div class="py-2 align-middle inline-block min-w-full sm:px-3 lg:px-8">
        <div class="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50" id="header">
              <tr>
                <th
                  scope="col"
                  class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-100"
                >
                  Vendor
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 1')"
                  >
                    Tier 1
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 2')"
                  >
                    Tier 2
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 3')"
                  >
                    Tier 3
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 4')"
                  >
                    Tier 4
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 5')"
                  >
                    Tier 5
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 6')"
                  >
                    Tier 6
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 7')"
                  >
                    Employee
                  </button>
                </th>
                <th scope="col">
                  <button
                    class="hover:bg-blue-200 cursor-pointer px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider rounded w-full h-full"
                    @click="setVendorPricing('Tier 8')"
                  >
                    Distributor
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Odd row -->
              <tr
                :class="index % 2 ? 'bg-white' : 'bg-gray-100'"
                v-for="(vendor, index) in vendors"
                :key="vendor._id"
              >
                <td class="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ vendor.name }}
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer hover:bg-blue-200"
                    style="padding-bottom: -2px"
                    :class="isTier(vendor.name, 'Tier 1')"
                    @click="setTier(vendor.name, 'Tier 1')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 2')"
                    @click="setTier(vendor.name, 'Tier 2')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 3')"
                    @click="setTier(vendor.name, 'Tier 3')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 4')"
                    @click="setTier(vendor.name, 'Tier 4')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 5')"
                    @click="setTier(vendor.name, 'Tier 5')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 6')"
                    @click="setTier(vendor.name, 'Tier 6')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 7')"
                    @click="setTier(vendor.name, 'Tier 7')"
                  ></div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="shadow bg-gray-100 w-6 h-6 m-auto rounded-full cursor-pointer"
                    :class="isTier(vendor.name, 'Tier 8')"
                    @click="setTier(vendor.name, 'Tier 8')"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mt-4 py-4 px-4 flex justify-end sm:px-3">
      <button
        class="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
        @click="saveTiers()"
      >
        Save tiers
      </button>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';

export default {
  data: function () {
    return {
      partNumber: null,
    };
  },
  props: {
    user: { type: Object, required: true },
    admin: { type: Boolean, default: false },
    currentUser: { type: Object, required: true },
  },
  computed: {
    vendors() {
      return this.$store.getters['tags/getTags']({ type: 'Vendor' });
    },
    variants() {
      console.info('UserPricing/variants');
      let variants = this.$store.state.products.variants;
      if (!variants) {
        return new Array();
      }

      if (this.partNumber) {
        const partNumber = this.partNumber.toLowerCase();
        variants = variants.filter((variant) => {
          if (
            variant &&
            variant.productNum &&
            variant.productNum.toLowerCase().includes(partNumber)
          ) {
            return variant;
          }
          if (
            variant &&
            variant.product_name &&
            variant.product_name.toLowerCase().includes(partNumber)
          ) {
            return variant;
          }
        });
      }

      return variants.slice(0, 25);
    },
    variantLoading() {
      return this.$store.getters['products/getLoading'];
    },
    company: {
      set(val) {
        this.$store.commit('company/setCompany', val);
      },
      get() {
        return this.$store.getters['company/getCompany'];
      },
    },
    companyLoading() {
      return this.$store.getters['company/getLoading'];
    },
    authorized() {
      switch (this.currentUser.email) {
        case 'gabrielsuttner@gmail.com':
        case 'pagehardware4less@gmail.com':
        case 'bob21softball@gmail.com':
          return true;
        default:
          return false;
      }
    },
  },
  watch: {},
  methods: {
    setTier(vendor, tier) {
      if (!this.authorized && (tier === 'Tier 7' || tier === 'Tier 8')) {
        this.$store.commit('setError', {
          message: 'You are not authorized to set this tier',
          color: 'red',
        });
        return;
      }
      if (this.company.tiers == undefined) {
        this.company.tiers = new Array();
      }
      for (let i = 0; i < this.company.tiers.length; i++) {
        if (vendor === this.company.tiers[i].vendor) {
          this.company.tiers[i].tier = tier;
          return;
        }
      }

      this.company.tiers.push({ vendor, tier: tier });
      this.$store.dispatch('createLog', {
        userEmail: this.currentUser.email,
        type: 'Company',
        category: 'Update',
        name: `${this.company.name}`,
        description: `Set  ${vendor} to ${tier}`,
      });
    },

    isTier(vendor, tier) {
      if (this.company.tiers != undefined) {
        for (let i = 0; i < this.company.tiers.length; i++) {
          if (vendor === this.company.tiers[i].vendor) {
            if (tier === this.company.tiers[i].tier) {
              return 'bg-blue-500';
            }
            return 'hover:bg-blue-200';
          }
        }
      }
      return 'hover:bg-blue-200';
    },
    setVendorPricing(tier) {
      if (!this.authorized && (tier === 'Tier 7' || tier === 'Tier 8')) {
        this.$store.commit('setError', {
          message: 'You are not authorized to set this tier',
          color: 'red',
        });
        return;
      }
      if (confirm(`Are you sure you would like to change ALL TIERS to ${tier}?`)) {
        this.company.tiers = [];

        this.vendors.forEach((el) => {
          this.company.tiers.push({ vendor: el.name, tier });
        });
      }
      this.$store.dispatch('createLog', {
        userEmail: this.currentUser.email,
        type: 'Company',
        category: 'Update',
        name: `${this.company.name}`,
        description: `Set all vendor pricing to ${tier}`,
      });
    },
    checkPrice(variant) {
      if (variant.price < variant.cost) {
        this.$store.commit('setError', {
          message: 'Price is less than cost',
          color: 'red',
        });
      }
    },
    removeVariant(productNum) {
      this.company.products = this.company.products.filter((el) => {
        return el.productNum != productNum;
      });
      this.$store.dispatch('company/removeSpecialPricedItem', {
        productNum,
        company_id: this.company._id,
      });
    },

    saveToSpecialPricing(variant) {
      const body = {
        company_id: this.company._id,
        product_name: variant.product_name,
        product_id: variant.product_id,
        productNum: variant.productNum,
        _id: variant._id,
        image: variant.image,
        cost: variant.cost,
        price: variant.price,
        option: variant.option,
      };

      this.$store.dispatch('company/saveToSpecialPricing', body);
      variant.saved = true;
    },
    addToVariants(variant) {
      if (this.company.products === null) {
        this.company.products = new Array();
      }

      try {
        this.company.products.some((el) => {
          if (el.saved === false) {
            throw new Error('Not Saved');
          } else if (variant._id === el._id) {
            throw new Error('Duplicate');
          }
        });
      } catch (error) {
        let message = '';
        console.log(error);

        switch (error.message) {
          case 'Duplicate':
            message = 'Duplicate product being added';
            break;
          default:
            message = 'Before adding any other products, please save ' + error.message;
            break;
        }

        this.$store.commit('setError', {
          message,
          color: 'red',
          duration: 0,
        });
        return;
      }
      variant.saved = false;

      let options = '';
      variant.options.forEach((el) => {
        console.log(el);
        if (el) options += `${el} `;
      });

      variant.option = options;
      this.company.products.push(variant);
      this.partNumber = null;
    },
    saveTiers() {
      this.$store.dispatch('company/saveTiers', this.company);
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch('products/getAllVariants');
    this.$store.dispatch(
      'company/getCompanyLimited',
      `?company_id=${this.user.company._id}&filter=tiers,products`
    );
  },
};
</script>
<style scoped>
#header {
  /* bg-primary fixed top-5 w-full rounded-tl rounded-tr */
}
</style>
