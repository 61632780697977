<template>
  <div class="m-2" v-if="admin">
    <div class="grid grid-cols-5 font-bold border-b">
      <div>Category</div>
      <div>Action</div>
      <div>Date</div>
      <div class="overflow-hidden">Email</div>
      <div class="text-right">Altered Object</div>
    </div>
    <Entry
      v-for="(entry, index) in entries"
      :key="entry._id"
      :entry="entry"
      class="p-2"
      :class="index % 2 ? 'bg-gray-200' : null"
    />
  </div>
  <div v-else>Not authorized</div>
</template>

<script>
import Entry from './../logger/Entry';

export default {
  props: {
    user: Object,
    admin: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    entries() {
      return this.$store.getters.getLogs;
    },
  },
  methods: {
    async fetchData() {
      this.$store.dispatch(
        'getLogs',
        `?type=User&type=Company&name=${this.user.email}&name=${this.user.businessName}&sort=-date`
      );
    },
  },
  components: {
    Entry,
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style></style>
