<template>
  <main class="relative pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
    <div class="bg-white rounded-lg shadow overflow-hidden">
      <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside class="py-6">
          <UserNavigation :commercial="commercial" />
        </aside>

        <div class="divide-y divide-gray-200 lg:col-span-11">
          <div class="relative bg-primary pb-25 overflow-hidden">
            <div
              class="inset-y-0 absolute flex justify-end inset-x-0 left-1/2 transform -translate-x-1/2 lg:-translate-x-64 w-full overflow-hidden lg:inset-y-0"
              aria-hidden="true"
            >
              <svg
                class="flex-grow -mt-32"
                width="1750"
                height="308"
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path opacity=".75" d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#00A0B1" />
                <path opacity=".75" d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#005785" />
              </svg>
            </div>
            <header class="relative py-5">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold text-white">{{ userName }}</h1>
                <h4 class="font-bold text-gray-500">
                  {{ userTitle }}
                </h4>
              </div>
            </header>
          </div>
          <span v-if="loading">
            <Loading />
          </span>
          <router-view
            v-else
            :user="user"
            :page="true"
            :admin="admin"
            :userName="userName"
            :currentUser="currentUser"
            :commercial="commercial"
            @updateUser="updateUser"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import UserNavigation from './UserNavigation';
import Loading from '@/components/shared/Loading';
export default {
  data: function () {
    return {};
  },
  props: {},
  computed: {
    commercial() {
      return this.user && this.user.permissionLevel === 'Commercial';
    },
    currentUser() {
      return this.$store.getters['user/getUser'];
    },
    user: {
      get() {
        return this.$store.getters['users/getUser'];
      },
      set(val) {
        this.$store.state.admin.user = val;
      },
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    loading() {
      return this.$store.getters['users/getLoading'];
    },
    orders() {
      if (this.user.company) {
        return this.$store.getters['company/getOrders'];
      } else {
        return this.$store.getters['users/getOrders'];
      }
    },
    routeId() {
      return this.user ? this.user._id : null;
    },
    userName() {
      return this.user ? this.user.name : 'Loading...';
    },
    userTitle() {
      let title = '...';
      if (this.user) {
        title = this.user.jobTitle
          ? `${this.user.jobTitle} of ${this.user.businessName}`
          : this.user.accountNumber
          ? `Account #${this.user.accountNumber} (${this.user.businessName})`
          : this.user.permissionLevel;
      }
      return title;
    },
  },
  watch: {
    routeId() {
      if (this.routeId != this.$route.params.id) {
        this.$router.push('/admin/users/' + this.routeId);
      }
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch('users/getUser', this.$route.params.id);
    },
    updateUser() {
      delete this.user.orders;
      if (!this.user.name) {
        this.$store.commit('setError', {
          message: 'You cannot save a user without a name. Please enter a name and try again.',
          color: 'red',
          duration: 6000,
        });
        return;
      }
      if (!this.user.email) {
        this.$store.commit('setError', {
          message: 'You cannot save a user without an email. Please enter an email and try again.',
          color: 'red',
          duration: 6000,
        });
        return;
      }

      if (!this.user.permissionLevel) {
        this.$store.commit('setError', {
          message:
            'You cannot save a user without setting their account type. Please select an account type and try again.',
          color: 'red',
          duration: 6000,
        });
        return;
      }
      if (this.user.permissionLevel == 'Commercial' && !this.user.accountRole) {
        this.$store.commit('setError', {
          message:
            'You cannot save a commercial user without setting their account role to primary or secondary. Please select an account role and try again.',
          color: 'red',
          duration: 6000,
        });
        return;
      }
      this.$store.dispatch('users/updateUser', this.user);
      this.altered = false;
    },

    deleteUser() {
      if (!confirm('Are you sure you want to delete ' + this.user.name + '?')) return;
      this.$store.dispatch('users/deleteUser', this.user);
      this.$router.push('/admin/users');
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    UserNavigation,
    Loading,
  },
};
</script>
<style scoped></style>
