<template>
  <div>
    <div v-if="loading">
      <Loading />
    </div>
    <div class="col-span-9 overflow-auto relative" v-else-if="company">
      <button class="absolute right-1 top-0 text-3xl md:text-5xl text-black" @click="handleLock()">
        <span v-if="company.locked">
          <font-awesome-icon icon="lock" />
        </span>
        <span v-else>
          <font-awesome-icon icon="unlock" />
        </span>
      </button>
      <div class="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Company Information</h3>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              autocomplete="new-password"
              v-model="company.name"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="email_address" class="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="text"
              name="email_address"
              id="email_address"
              v-model="company.email"
              autocomplete="new-password"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              name="phone"
              v-model="company.phone"
              autocomplete="new-password"
              id="phone"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-1">
            <label for="account_number" class="block text-sm font-medium text-gray-700">
              Account number
            </label>
            <input
              type="text"
              name="account_number"
              v-model="company.accountNumber"
              autocomplete="new-password"
              id="account_number"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div class="col-span-6 sm:col-span-1">
            <label for="credit_limit" class="block text-sm font-medium text-gray-700">
              Credit Limit
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm"> $ </span>
              </div>
              <input
                type="text"
                name="credit_limit"
                v-model="company.creditLimit"
                id="credit_limit"
                class="border border-gray-300 focus:border-blue-500 block w-full pl-6 pr-12 sm:text-sm rounded-md"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency"> USD </span>
              </div>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-1">
            <label for="credit_limit" class="block text-sm font-medium text-gray-700"
              >Running Total</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm"> $ </span>
              </div>
              <input
                type="text"
                name="credit_limit"
                v-model="company.runningTotal"
                id="credit_limit"
                class="border border-gray-300 focus:border-blue-500 block w-full pl-6 pr-12 sm:text-sm rounded-md"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency"> USD </span>
              </div>
            </div>
          </div>
          <div class="">
            <div class="flex justify-between items-center">
              <label for="store_credit" class="block text-sm font-medium text-gray-700">
                Store Credit
              </label>
              <div class="flex items-center" v-if="admin">
                <label for="edit_store_credit" class="text-xs text-gray-600 mr-2">edit</label>
                <input
                  type="checkbox"
                  id="edit_store_credit"
                  class="border border-gray-400 rounded"
                  v-model="editStoreCredit"
                />
              </div>
            </div>
            <input
              type="number"
              name="store_credit"
              id="store_credit"
              :disabled="!admin || !editStoreCredit"
              v-model="company.inStoreCredit"
              autocomplete="off"
              :class="!admin || !editStoreCredit ? 'text-gray-400 border-gray-100' : ''"
              class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
            />
          </div>
          <div class="col-span-6 sm:col-span-1 ml-1" @click="setTaxExempt()">
            <label for="tax_exempt" class="block text-sm font-medium text-gray-700">
              Tax exempt
            </label>
            <div class="py-2" :key="taxKey">
              <button
                type="button"
                class="bg-action relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                role="switch"
                aria-checked="false"
              >
                <span class="sr-only">Tax exempt</span>
                <span
                  :class="company.taxExempt ? 'translate-x-5' : 'translate-x-0'"
                  aria-hidden="true"
                  class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                ></span>
              </button>
            </div>
          </div>
          <div class="">
            <div class="flex justify-between items-center">
              <label for="qb_id" class="block text-sm font-medium text-gray-700">
                Quickbooks id
              </label>
              <div class="flex items-center" v-if="admin">
                <label for="edit_qb" class="text-xs text-gray-600 mr-2">edit</label>
                <input
                  type="checkbox"
                  id="edit_qb"
                  class="border border-gray-400 rounded"
                  v-model="editQb"
                />
              </div>
            </div>
            <input
              type="number"
              name="qb_id"
              id="qb_id"
              :disabled="!admin || !editQb"
              v-model="company.quickbooksId"
              autocomplete="off"
              :class="!admin || !editQb ? 'text-gray-400 border-gray-100' : ''"
              class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
            />
          </div>
          <div class="col-span-6 sm:col-span-1">
            <div class="flex justify-between items-center">
              <label for="ein" class="block text-sm font-medium text-gray-700">Tax Id</label>
              <div class="flex items-center" v-if="admin">
                <label for="edit_tax" class="text-xs text-gray-600 mr-2">edit</label>
                <input
                  type="checkbox"
                  id="edit_tax"
                  class="border border-gray-400 rounded"
                  v-model="editTaxId"
                />
              </div>
            </div>
            <input
              type="text"
              name="ein"
              id="ein"
              autocomplete="new-password"
              :disabled="!admin || !editTaxId"
              v-model="company.ein"
              :class="!admin || !editTaxId ? 'text-gray-400 border-gray-100' : ''"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div class="relative flex flex-col">
            <label for="salesman">Salesman</label>
            <div>
              <button
                class="absolute top-0 right-0 mr-2 hover:bg-red-200 px-2 -mb-px rounded"
                @click="clearSalesman()"
              >
                <font-awesome-icon icon="times" />
              </button>
              <select
                id="salesman"
                class="shadow styled py-1 mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
                v-model="company.salesman"
              >
                <option v-for="salesman in employees" :key="salesman._id" :value="salesman.name">
                  {{ salesman.name }}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="relative flex flex-col">
            <button @click="runClean()" class="btn-primary rounded">run</button>
          </div> -->
        </div>
      </div>

      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Company Address</h3>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="street_address" class="block text-sm font-medium text-gray-700"
              >Street address</label
            >
            <input
              type="text"
              name="street_address"
              id="street_address"
              v-model="company.address"
              autocomplete="new-password"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-6 lg:col-span-2">
            <label for="city" class="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              name="city"
              v-model="company.city"
              autocomplete="new-password"
              id="city"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="state" class="block text-sm font-medium text-gray-700">
              State / Province
            </label>
            <input
              type="text"
              name="state"
              id="state"
              autocomplete="new-password"
              v-model="company.state"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="postal_code" class="block text-sm font-medium text-gray-700">
              ZIP / Postal
            </label>
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              v-model="company.zip"
              autocomplete="new-password"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Company Users</h3>
        </div>
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-4 flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>

                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Odd row -->
                      <tr
                        class="bg-white"
                        v-for="(person, index) in company.users"
                        :class="index % 2 ? 'bg-gray-100' : 'bg-white'"
                        :key="`${person._id}-1`"
                      >
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ person.name }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ person.jobTitle }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ person.email }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-between"
                        >
                          <button
                            @click="gotoLink(person._id)"
                            class="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </button>
                          <button
                            class="hover:bg-red-300 bg-gray-200 rounded px-2 ml-2"
                            @click="removeAssociatedUser(person._id)"
                            v-if="currentUsersAccount(person._id)"
                          >
                            <font-awesome-icon icon="times" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-span-2">
            <label id="listbox-label" class="block text-sm font-medium text-gray-700 relative">
              Add associated account
              <!-- Legend -->
              <div class="absolute right-1 top-0">
                <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip">
                  <font-awesome-icon class="text-lg" icon="info-circle" />
                  <span
                    class="tooltiptext shadow text-black p-1 flex flex-col w-64 text-left right-0"
                  >
                    If a user is not in this list, that customer is not marked as a commercial
                    customer.
                  </span>
                </div>
              </div>
              <!-- End Legend -->
            </label>
            <CustomerSelect
              @setUser="assignUser"
              :users="users"
              :loading="false"
              @getCustomerSelectUsers="getUsers"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 py-4 px-4 flex justify-between sm:px-6">
        <AssignCompany :company="company" :user="user" />
        <button
          v-if="company.quickbooksId"
          class="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
          @click="saveCompany"
        >
          Save
        </button>
        <div v-else>
          <DualButton
            :options="dualButtonOptions"
            verticalPosition="top"
            @execute="handleButtonMethods($event)"
            position="right"
          />
        </div>
      </div>
    </div>
    <div v-else class="py-6 px-4 space-y-6 sm:p-6">
      <AssignCompany :user="user" />
    </div>
  </div>
</template>

<script>
import CustomerSelect from '@/components/admin/shared/CustomerSelect';
import Loading from '@/components/shared/Loading';
import AssignCompany from './AssignCompany.vue';
import DualButton from '@/components/shared/DualButton';

export default {
  data: function () {
    return {
      taxKey: 0,
      paymentKey: 0,
      dualButtonOptions: [{ name: 'Save' }, { name: 'Sync' }],
      editQb: false,
      editTaxId: false,
      editStoreCredit: false,
    };
  },
  props: {
    admin: { type: Boolean, required: false },
    user: { type: Object, required: true },
  },
  computed: {
    loading() {
      return this.$store.getters['company/getLoading'];
    },
    users() {
      return this.$store.getters['users/getCommercialUsers'];
    },
    company: {
      set(val) {
        this.$store.commit('company/setCompany', val);
      },
      get() {
        return this.$store.getters['company/getCompany'];
      },
    },
    employees() {
      return this.$store.getters['users/getEmployees'];
    },
  },
  watch: {},
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Save':
          this.saveCompany();
          break;
        default:
          this.quickbooks();
          break;
      }
    },
    setTaxExempt() {
      this.company.taxExempt = !this.company.taxExempt;
      this.taxKey++;
    },

    assignUser(user) {
      if (!Array.isArray(this.company.users)) {
        this.company.users = new Array();
      }
      this.company.users = this.company.users.filter((el) => {
        if (el) return el;
      });

      this.company.users.push(user);
    },
    removeAssociatedUser(id) {
      this.company.users = this.company.users.filter((el) => {
        return el._id !== id;
      });
    },
    gotoLink(id) {
      let routeData = this.$router.resolve({ name: 'User Info', params: { id } });
      window.open(routeData.href, '_blank');
    },
    saveCompany() {
      this.$store.dispatch('company/saveCompany', this.company);
    },
    clearSalesman() {
      this.company.salesman = null;
    },
    getUsers(value) {
      this.$store.dispatch('users/getCommercialUsers', `&search=${value}`);
    },
    currentUsersAccount(id) {
      return !(id === this.user._id);
    },
    handleLock() {
      this.company.locked = !this.company.locked;
      if (this.company.locked) {
        this.$store.commit('setError', {
          message: `${this.company.name} will not be able to place orders until the account is unlocked.`,
          color: 'red',
          duration: 10000,
        });
      } else {
        this.$store.commit('setError', {
          message: `${this.company.name} can now place orders unless their running total has exceeded their credit limit.`,
          color: 'green',
          duration: 10000,
        });
      }
    },
    quickbooks() {
      this.$store.dispatch('quickbooks/syncCompany', { _id: this.company._id });
    },
    runClean() {
      this.$store.dispatch('company/runClean');
    },
  },
  mounted() {
    if (this.user.company)
      this.$store.dispatch(
        'company/getCompanyLimited',
        `?company_id=${this.user.company._id}&filter=users`
      );
    this.$store.dispatch('company/getCompanies', 'sort=name');
  },
  components: {
    CustomerSelect,
    Loading,
    DualButton,
    AssignCompany,
  },
};
</script>
<style scoped></style>
