<template>
  <div>
    <nav class="space-y-1 flex flex-col md:flex-row lg:flex-col justify-between">
      <router-link
        :to="`/admin/users/${user_id}`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        aria-current="page"
        :class="currentlySelected('User Info')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('User Info')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="truncate"> Profile </span>
      </router-link>

      <router-link
        :to="`/admin/users/${user_id}/billing`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('User Billing')"
      >
        <!-- Heroicon name: outline/credit-card -->
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('User Billing')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          />
        </svg>
        <span class="truncate"> Billing </span>
      </router-link>
      <router-link
        :to="`/admin/users/${user_id}/company`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Company')"
        v-if="commercial"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Company')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
          />
        </svg>
        <span class="truncate"> Company </span>
      </router-link>
      <router-link
        :to="`/admin/users/${user_id}/special-pricing`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('User Special Pricing')"
        v-if="commercial"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('User Special Pricing')"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
            clip-rule="evenodd"
          />
        </svg>

        <span class="truncate"> Products </span>
      </router-link>

      <router-link
        :to="`/admin/users/${user_id}/orders`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('User Orders')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('User Orders')"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
          <path
            d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"
          />
        </svg>
        <span class="truncate"> Orders </span>
      </router-link>
      <router-link
        :if="admin"
        :to="`/admin/users/${user_id}/logs`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('User Logs')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('User Logs')"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        <span class="truncate"> Logs </span>
      </router-link>
      <button
        class="group px-3 py-2 flex items-center text-sm font-medium flex-grow"
        v-if="commercial"
        @click="loginAs()"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <g><rect fill="none" height="24" width="24" /></g>
          <g>
            <path
              d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"
            />
          </g>
        </svg>

        <span class="truncate"> Login as User </span>
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    commercial: { type: Boolean, default: false },
    admin: { type: Boolean, default: false },
  },
  computed: {
    name() {
      return this.$route.name;
    },
    user_id() {
      return this.$route.params.id;
    },
  },
  watch: {},
  methods: {
    currentlySelected(name) {
      return name === this.name
        ? 'bg-blue-100 border-action text-action hover:bg-teal-50 hover:text-primary'
        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900';
    },
    currentlySelectedIcon(name) {
      return name === this.name
        ? 'text-action group-hover:text-primary'
        : 'text-gray-400 group-hover:text-gray-500';
    },
    loginAs() {
      this.$store.dispatch('loginAs', this.user_id);
      this.$router.push('/');
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
