<template>
  <div class="min-h-screen">
    <div class="md:mx-auto">
      <!-- Search -->
      <div class="flex flex-col sm:flex-row items-center relative">
        <form class="relative w-80 mr-4" @submit.prevent="clearData" @keydown.esc="clearSearch">
          <button class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1" type="submit">
            <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
          </button>

          <input
            type="text"
            class="bg-white w-full rounded-full pl-12 py-2 outline-none"
            v-model="search"
            placeholder="Username or account #"
          />
        </form>
        <button @click="createNewUser" class="px-4 text-primary hover:bg-primary-alt py-1 rounded">
          + Add User
        </button>
        <div></div>
        <div class="flex flex-col">
          <label for="destination">Destination</label>
          <div>
            <select
              id="destination"
              name="destination"
              class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
              v-model="defaultDestination"
            >
              <option value="profile">Profile</option>
              <option value="company">Company</option>
              <option value="billing">Billing</option>
              <option value="products">Products</option>
              <option value="orders">Orders</option>
            </select>
          </div>
        </div>
        <div class="relative flex flex-col mx-2">
          <label for="salesman">Salesman</label>
          <div>
            <button
              class="absolute top-0 right-0 mr-2 hover:bg-red-200 px-2 -mb-px rounded"
              @click="clearSalesman"
            >
              <font-awesome-icon icon="times" />
            </button>
            <select
              id="salesman"
              class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
              v-model="salesman"
              @change="clearData"
            >
              <option v-for="salesman in employees" :key="salesman._id" :value="salesman.name">
                {{ salesman.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="destination">Account type</label>
          <div>
            <select
              id="destination"
              name="destination"
              class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
              v-model="accountType"
              @change="clearData"
            >
              <option value="All">All</option>
              <option value="Retail">Retail</option>
              <option value="Commercial">Commercial</option>
              <option value="Employee">Employee</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
        </div>
      </div>
      <!-- End Search -->

      <!-- User List -->
      <div class="flex flex-col mt-4">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-primary text-white">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                    >
                      <button @click="setSort('name')">
                        Name
                        <font-awesome-icon
                          icon="chevron-down"
                          class="transition-transform duration-200"
                          :class="sort === 'name' ? 'flip' : null"
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                    >
                      <button @click="setSort('email')">
                        Email
                        <font-awesome-icon
                          icon="chevron-down"
                          class="transition-transform duration-200"
                          :class="sort === 'email' ? 'flip' : null"
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                    >
                      <button @click="setSort('businessName')">
                        Business
                        <font-awesome-icon
                          icon="chevron-down"
                          class="transition-transform duration-200"
                          :class="sort === 'businessName' ? 'flip' : null"
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                    >
                      <button @click="setSort('salesman')">
                        Salesman
                        <font-awesome-icon
                          icon="chevron-down"
                          class="transition-transform duration-200"
                          :class="sort === 'salesman' ? 'flip' : null"
                        />
                      </button>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 relative" v-if="loading">
                  <tr class="">
                    <th colspan="5">
                      <Loading />
                    </th>
                  </tr>
                </tbody>
                <tbody class="bg-white divide-y divide-gray-200" v-else-if="users.length > 0">
                  <tr v-for="person in users" :key="person._id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <button class="relative h-10 w-10 hover:ring-1">
                          <div
                            v-if="
                              person.permissionLevel === 'Commercial' &&
                              person.company &&
                              !person.company.quickbooksId
                            "
                            class="absolute -left-1 -top-1 text-red pulse"
                          >
                            <font-awesome-icon icon="exclamation-triangle" />
                          </div>
                          <div
                            class="rounded-full overflow-hidden userCheckbox items-center text-center"
                            @click="goToUserEdit(person)"
                          >
                            <img
                              v-if="person.avatar"
                              class="h-10 w-10"
                              :src="person.avatar"
                              alt=""
                            />
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              class="flex-shrink-0 h-10 w-10 rounded-full"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </button>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ person.name }}
                          </div>
                          <div class="text-sm text-gray-500">
                            {{ person.permissionLevel }}
                            <span v-if="person.permissionLevel === 'Commercial'">
                              ({{ person.accountRole }})
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="text-sm text-gray-900">
                        {{ person.email }}
                      </div>
                      <div class="text-sm text-gray-500" v-if="person.jobTitle">
                        {{ person.jobTitle }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900" v-if="person.company">
                        {{ person.company.name }}
                      </div>
                      <div class="text-sm text-gray-500" v-if="person.company">
                        {{ person.company.accountNumber }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap" v-if="person.company">
                      {{ person.company.salesman }}
                    </td>
                    <td v-else class="px-6 py-4 whitespace-nowrap"></td>

                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        @click="goToUserEdit(person)"
                        class="text-action hover:text-blue-900 uppercase"
                      >
                        {{ getDestination(person) }}
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody class="bg-white divide-y divide-gray-200" v-else>
                  <tr>
                    <th colspan="5">No users found</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pagination -->
    <Pagination
      pageType="User"
      :totalAvailable="userTotal"
      :limit="limit"
      :currentPage="currentPage"
      :selectableLimits="selectableLimits"
      @setCurrentPage="setCurrentPage"
      @setLimit="setLimit"
    />
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';
import Pagination from '@/components/shared/Pagination';

export default {
  data: () => {
    return {
      defaultDestination: 'profile',
      salesman: null,
      search: null,
      sort: 'name',
      accountType: 'All',

      limit: 25,
      currentPage: 1,
      selectableLimits: [50, 100, 200, 1000],
    };
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers'];
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    employees() {
      return this.$store.getters['users/getEmployees'];
    },
    userTotal() {
      return this.$store.getters['users/getQueryTotal'];
    },
    loading() {
      return this.$store.getters['users/getLoading'];
    },
  },
  watch: {
    defaultDestination() {
      window.localStorage.setItem('defaultDestination', this.defaultDestination);
    },
    loading() {
      this.scrollToPriorLocation();
    },
  },
  methods: {
    setLimit(payload) {
      this.limit = payload;
      this.clearData();
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
      this.clearData();
    },
    getDestination(person) {
      switch (this.defaultDestination) {
        case 'profile':
          return 'Profile';
        case 'billing':
          return 'Billing';
        case 'orders':
          return 'Orders';
        case 'products':
          if (person.permissionLevel === 'Commercial') {
            return 'products';
          }
          return 'profile';
        case 'company':
          if (person.permissionLevel === 'Commercial') {
            return 'company';
          }
          return 'profile';
      }
    },

    clearData() {
      this.$store.commit('users/clearUsers');
      this.$store.commit('setScroll');
      this.buildQuery();
      this.fetchData();
    },
    buildQuery() {
      const query = {
        sort: this.sort,
        page: this.currentPage,
        limit: this.limit,
        salesman: this.salesman,
        search: this.search,
        permissionLevel: this.accountType != 'All' ? this.accountType : null,
      };

      this.$router
        .push({
          name: this.$route.name,
          query,
        })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error);
          }
        });
    },

    fetchData() {
      this.$store.dispatch('users/getUsers', this.$route.query);
    },
    setUpPage() {
      const query = this.$route.query;

      this.limit = query.limit ? query.limit : 25;
      this.currentPage = query.page ? query.page : 1;
      this.accountType = query.permissionLevel ? query.permissionLevel : 'All';
      this.search = query.search ? query.search : null;
      this.salesman = query.salesman ? query.salesman : null;
      this.fetchData();
    },

    goToUserEdit(person) {
      let route = '';
      switch (this.defaultDestination) {
        case 'profile':
          route = `/admin/users/${person._id}`;
          break;
        case 'billing':
          route = `/admin/users/${person._id}/billing`;
          break;
        case 'orders':
          route = `/admin/users/${person._id}/orders`;
          break;
        case 'company':
          if (person.permissionLevel === 'Commercial') {
            route = `/admin/users/${person._id}/company`;
          } else {
            route = `/admin/users/${person._id}`;
          }
          break;
        case 'products':
          if (person.permissionLevel === 'Commercial') {
            route = `/admin/users/${person._id}/special-pricing`;
          } else {
            route = `/admin/users/${person._id}`;
          }
          break;
      }
      this.$store.commit('setScroll', {
        name: this.$route.name,
        position: parseInt(window.scrollY),
      });
      console.log(route);
      this.$router.push(route);
    },
    clearSalesman() {
      this.salesman = null;
      this.clearData();
    },
    clearSearch() {
      this.search = null;
      this.clearData();
    },
    setSort(value) {
      if (this.sort === value) {
        this.sort = `-${value}`;
      } else {
        this.sort = value;
      }
      this.clearData();
    },
    createNewUser() {
      this.$store.commit('company/setCompany', null);
      this.$router.push('/admin/users/new');
    },
    scrollToPriorLocation() {
      if (!this.loading) {
        const scroll = this.$route.query.scrollTo
          ? this.$route.query.scrollTo
          : this.$store.getters['getScroll'](this.$route.name);
        if (scroll) {
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: scroll,
              behavior: 'smooth',
            });
          }, 500);
        }
      }
    },
  },
  components: {
    Loading,
    Pagination,
  },
  mounted() {
    const defaultDestination = window.localStorage.getItem('defaultDestination');
    if (defaultDestination && defaultDestination != 'null') {
      this.defaultDestination = defaultDestination;
    }
    this.setUpPage();
  },
};
</script>

<style scoped>
.selected {
  outline: #00a0b1 solid 1px;
  outline-offset: 1px;
}

.flip {
  transform: rotate(180deg);
}

label {
  padding-left: 0.2rem;
}
</style>
