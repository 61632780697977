<template>
  <div>
    <div class="col-span-9">
      <div class="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Billing Information</h3>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <div>
              <label id="listbox-label" class="block text-sm font-medium text-gray-700">
                Payment Method
              </label>
              <div class="mt-1 relative">
                <button
                  type="button"
                  class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:border-blue-500 sm:text-sm h-10"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                  @click="showPaymentMethod = !showPaymentMethod"
                >
                  <span class="block truncate"> {{ user.paymentMethod }} </span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <!-- Heroicon name: solid/selector -->
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <ul
                  v-if="showPaymentMethod"
                  class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3"
                >
                  <li
                    class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-blue-600"
                    @click="(user.paymentMethod = 'Credit Card'), (showPaymentMethod = false)"
                  >
                    <span class="font-normal block truncate"> Credit Card </span>
                  </li>
                  <li
                    class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-blue-600"
                    @click="(user.paymentMethod = 'Check'), (showPaymentMethod = false)"
                  >
                    <span class="font-normal block truncate"> Check </span>
                  </li>
                  <li
                    class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-blue-600"
                    @click="(user.paymentMethod = 'Cash'), (showPaymentMethod = false)"
                  >
                    <span class="font-normal block truncate"> Cash </span>
                  </li>
                  <li
                    class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-blue-600"
                    @click="(user.paymentMethod = 'Wire Transfer'), (showPaymentMethod = false)"
                  >
                    <span class="font-normal block truncate"> Wire Transfer </span>
                  </li>
                  <li
                    class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-blue-600"
                    @click="(user.paymentMethod = 'Terms'), (showPaymentMethod = false)"
                  >
                    <span class="font-normal block truncate"> Terms </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Billing Address</h3>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="street_address" class="block text-sm font-medium text-gray-700"
              >Street address</label
            >
            <input
              type="text"
              name="street_address"
              id="street_address"
              v-model="user.billingAddress"
              autocomplete="new-password"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-6 lg:col-span-2">
            <label for="city" class="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              name="city"
              v-model="user.billingCity"
              autocomplete="new-password"
              id="city"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="state" class="block text-sm font-medium text-gray-700"
              >State / Province</label
            >
            <input
              type="text"
              name="state"
              id="state"
              autocomplete="new-password"
              v-model="user.billingState"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="postal_code" class="block text-sm font-medium text-gray-700"
              >ZIP / Postal</label
            >
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              v-model="user.billingZip"
              autocomplete="new-password"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
      <button
        class="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
        @click="$emit('updateUser')"
      >
        Save company info
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      showPaymentMethod: false,
      taxKey: 0,
      paymentKey: 0,
    };
  },
  props: {
    user: { type: Object, require: true },
    admin: { type: Boolean, require: true },
  },
  computed: {
    paymentMethod() {
      return this.user.paymentMethod;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
