<template>
  <div class="relative">
    <input
      v-if="loading"
      type="text"
      disabled="true"
      placeholder="Retrieving data from server..."
      class="relative w-full bg-gray-400 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm"
    />
    <input
      v-else
      autocomplete="new-password"
      type="text"
      name=""
      id="userInput"
      v-model="search"
      @keydown.enter="fetchData()"
      @keydown.esc="(search = null), ($event.target.value = null)"
      class="relative w-full styled shadow rounded"
    />

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-auto focus:outline-none sm:text-sm z-10"
        v-if="showUsers"
      >
        <ul v-if="users.length > 0">
          <li
            v-for="person in users"
            :key="person._id"
            class="select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-blue-200 cursor-pointer"
            @click="setUser(person)"
          >
            <img
              v-if="person.avatar"
              :src="person.avatar"
              alt=""
              class="flex-shrink-0 h-6 w-6 rounded-full"
            />
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="flex-shrink-0 h-6 w-6 rounded-full"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              />
            </svg>
            <div class="ml-3 block truncate text-sm">
              <div>
                {{ person.name }}
              </div>
              <div class="text-gray-500">
                <span v-if="person.company">{{ person.company.name }}</span>
                {{ person.jobTitle ? `(${person.jobTitle})` : null }}
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <li class="cursor-default select-none relative py-2 pl-3 pr-9">No users found</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      search: null,
    };
  },
  props: {
    loading: { type: Boolean, require: false, default: true },
    users: { type: Array, require: true },
  },
  computed: {
    showUsers() {
      return this.search && this.search.length > 0;
    },
  },
  watch: {},
  methods: {
    setUser(person) {
      this.search = null;
      this.$emit('setUser', person);
    },
    fetchData() {
      this.$emit('getCustomerSelectUsers', this.search);
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
