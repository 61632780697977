<template>
  <div>
    <!-- Profile section -->
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div class="my-6 flex flex-col lg:flex-row">
        <div class="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
          <div class="mt-1 lg:hidden">
            <div class="flex items-center">
              <div
                class="flex-shrink-0 inline-block rounded overflow-hidden h-12 w-12"
                aria-hidden="true"
              >
                <img v-if="user.avatar" class="rounded h-full w-full" :src="user.avatar" alt="" />
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-full w-full rounded"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="hidden relative rounded overflow-hidden lg:block">
            <img v-if="user.avatar" class="relative rounded w-40 h-40" :src="user.avatar" alt="" />
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="w-40 h-40 rounded"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-4 sm:col-span-3">
          <div class="flex justify-between">
            <label for="first_name" class="block text-sm font-medium text-gray-700">
              Full name
            </label>
            <span class="text-sm text-gray-500" id="email-optional">Required</span>
          </div>
          <input
            type="text"
            name="first_name"
            id="first_name"
            v-model="user.name"
            autocomplete="given-name"
            class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
          />
        </div>
        <div class="col-span-3 row-span-2">
          <label for="notes" class="block text-sm font-medium text-gray-700">Notes</label>
          <textarea
            type="text"
            name="notes"
            id="notes"
            v-model="user.notes"
            autocomplete="given-name"
            class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
          />
        </div>

        <div class="col-span-4 sm:col-span-3">
          <div class="flex justify-between">
            <label for="email_address" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <span class="text-sm text-gray-500" id="email-optional">Required</span>
          </div>
          <input
            type="text"
            name="email_address"
            id="email_address"
            v-model="user.email"
            autocomplete="off"
            class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
          />
        </div>

        <div class="col-span-4 sm:col-span-3">
          <label for="phone" class="block text-sm font-medium text-gray-700">Phone number</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            v-model="user.phone"
            autocomplete="off"
            class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
          />
        </div>

        <div class="col-span-4 sm:col-span-3"></div>
        <div class="col-span-4 sm:col-span-2">
          <label for="job_title" class="block text-sm font-medium text-gray-700">Job title</label>
          <input
            type="text"
            name="job_title"
            id="job_title"
            v-model="user.jobTitle"
            autocomplete="off"
            class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
          />
        </div>

        <div class="col-span-4 sm:col-span-2" v-if="user.permissionLevel === 'Commercial'">
          <div class="flex justify-between">
            <label for="role" class="block text-sm font-medium text-gray-700"> Account role </label>
            <span class="text-sm text-gray-500" id="email-optional">Required</span>
          </div>
          <select
            id="role"
            v-model="user.accountRole"
            name="location"
            class="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option>Primary</option>
            <option>Secondary</option>
          </select>
        </div>
        <div class="col-span-4 sm:col-span-2">
          <label
            for="password"
            class="block text-sm font-medium"
            :class="changePassword ? 'text-gray-300' : 'text-gray-700'"
            >Change Password</label
          >
          <div class="flex">
            <input
              type="text"
              name="password"
              id="password"
              v-model="user.password"
              autocomplete="off"
              :disabled="changePassword"
              class="mt-1 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border"
            />
            <button
              @click="changePassword = !changePassword"
              class="flex mx-1 py-1 cursor-pointer text-primary"
            >
              <font-awesome-icon size="2x" icon="lock" v-if="changePassword" />
              <font-awesome-icon size="2x" icon="unlock" v-else />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">Address</h3>
      </div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6">
          <label for="street_address" class="block text-sm font-medium text-gray-700"
            >Street address</label
          >
          <input
            type="text"
            name="street_address"
            id="street_address"
            v-model="user.shippingAddress"
            autocomplete="new-password"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div class="col-span-6 sm:col-span-6 lg:col-span-2">
          <label for="city" class="block text-sm font-medium text-gray-700">City</label>
          <input
            type="text"
            name="city"
            v-model="user.shippingCity"
            autocomplete="new-password"
            id="city"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
          <label for="state" class="block text-sm font-medium text-gray-700"
            >State / Province</label
          >
          <select
            v-model="user.shippingState"
            name="billing-city"
            id="billing-city"
            autocomplete="address-level1"
            class="block w-full border-gray-300 border rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
            required
          >
            <option value="AL">AL</option>
            <option value="AK">AK</option>
            <option value="AR">AR</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DC">DC</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MO">MO</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="NC">NC</option>
            <option value="NE">NE</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="ND">ND</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
        </div>

        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
          <label for="postal_code" class="block text-sm font-medium text-gray-700"
            >ZIP / Postal</label
          >
          <input
            type="text"
            name="postal_code"
            id="postal_code"
            v-model="user.shippingZip"
            autocomplete="new-password"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>

    <!-- Privacy section -->
    <div class="pt-6 divide-y divide-gray-200">
      <div class="px-4 sm:px-6">
        <div>
          <h2 class="text-lg leading-6 font-medium text-gray-900">Account type</h2>
          <p class="mt-1 text-sm text-gray-500">
            Different account types have different permissions
          </p>
        </div>
        <ul class="mt-2 divide-y divide-gray-200" :key="permissionKey">
          <li class="py-4 flex items-center justify-between" @click="setPermission('Retail')">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-1-label">
                Retail Customer
              </p>
              <p class="text-sm text-gray-500" id="privacy-option-1-description">
                Retail customers can't have special priced items, save carts, choose local delivery,
                or pay with a method other than credit card
              </p>
            </div>
            <button
              type="button"
              class="bg-action ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
              role="switch"
              aria-checked="true"
              aria-labelledby="privacy-option-1-label"
              aria-describedby="privacy-option-1-description"
            >
              <span class="sr-only">Retail Customer</span>
              <span
                aria-hidden="true"
                class="inline-block h-5 w-5 rounded-full bg-gray-200 shadow transform ring-0 transition ease-in-out duration-200"
                :class="user.permissionLevel === 'Retail' ? 'translate-x-5' : 'translate-x-0'"
              ></span>
            </button>
          </li>
          <li class="py-4 flex items-center justify-between" @click="setPermission('Commercial')">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-2-label">
                Commercial Customer
              </p>
              <p class="text-sm text-gray-500" id="privacy-option-2-description">
                Commercial customers can have special priced items, save multiple carts, choose
                multiple delivery methods, and choose multiple payment methods
              </p>
            </div>
            <button
              type="button"
              class="bg-action ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="privacy-option-2-label"
              aria-describedby="privacy-option-2-description"
            >
              <span class="sr-only">Commercial Customer</span>
              <span
                aria-hidden="true"
                class="inline-block h-5 w-5 rounded-full bg-gray-200 shadow transform ring-0 transition ease-in-out duration-200"
                :class="user.permissionLevel === 'Commercial' ? 'translate-x-5' : 'translate-x-0'"
              ></span>
            </button>
          </li>
          <li class="py-4 flex items-center justify-between" @click="setPermission('Employee')">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-3-label">Employee</p>
              <p class="text-sm text-gray-500" id="privacy-option-3-description">
                Employees can't: create or alter coupons, change some user settings, alter some
                product options, or _____
              </p>
            </div>
            <button
              type="button"
              class="bg-action ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
              role="switch"
              aria-checked="true"
              aria-labelledby="privacy-option-3-label"
              aria-describedby="privacy-option-3-description"
            >
              <span class="sr-only">Employee</span>
              <span
                aria-hidden="true"
                class="translate-x-0 inline-block h-5 w-5 rounded-full bg-gray-200 shadow transform ring-0 transition ease-in-out duration-200"
                :class="user.permissionLevel === 'Employee' ? 'translate-x-5' : 'translate-x-0'"
              ></span>
            </button>
          </li>
          <li class="py-4 flex items-center justify-between" @click="setPermission('Admin')">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-4-label">Admin</p>
              <p class="text-sm text-gray-500" id="privacy-option-4-description">
                Admins have no restrictions.
              </p>
            </div>
            <button
              type="button"
              class="bg-action ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
              role="switch"
              aria-checked="true"
              aria-labelledby="privacy-option-4-label"
              aria-describedby="privacy-option-4-description"
            >
              <span class="sr-only">Admin</span>
              <span
                aria-hidden="true"
                class="translate-x-0 inline-block h-5 w-5 rounded-full bg-gray-200 shadow transform ring-0 transition ease-in-out duration-200"
                :class="user.permissionLevel === 'Admin' ? 'translate-x-5' : 'translate-x-0'"
              ></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-4 py-4 px-4 flex sm:px-6 justify-between">
      <button
        type="button"
        class="ml-5 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-red hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
        @click="deleteUser()"
      >
        Delete User
      </button>
      <button
        class="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
        @click="$emit('updateUser')"
      >
        Save user info
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      permissionKey: 0,
      changePassword: true,
    };
  },
  props: {
    user: { type: Object, require: true },
    admin: { type: Boolean, require: true },
  },
  computed: {},
  watch: {},
  methods: {
    setPermission(level) {
      this.permissionKey++;
      if (this.admin) {
        this.user.permissionLevel = level;
      } else if (level === 'Employee' || level === 'Admin') {
        this.$store.commit('setError', {
          message: 'You are not authorized to set this permission',
          color: 'red',
          duration: 0,
        });
      } else {
        this.user.permissionLevel = level;
      }
    },

    deleteUser() {
      this.$store.dispatch('users/deleteUser', this.user);
      this.$router.push('/admin/users');
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
