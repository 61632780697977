<template>
  <div>
    <div class="flex items-center justify-between md:max-w-xl">
      <div class="flex">
        <select
          class="shadow mr-2 focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
          v-model="selectedCompanyId"
        >
          <option :value="null"></option>
          <option v-for="company in companies" :key="company._id" :value="company._id">
            {{ company.name }}
          </option>
        </select>
      </div>
      <DualButton :options="buttonOptions" @execute="handleButtonMethods" verticalPosition="top" />
    </div>

    <PopUp
      v-if="showCreateCompany"
      title="Company Name"
      @close="showCreateCompany = false"
      :controlWidth="true"
    >
      <div class="flex flex-col">
        <div class="flex flex-col">
          <label for="company_name" style="">Company Name</label>
          <input
            type="text"
            class="rounded border-gray-200 border p-1 bg-white"
            id="company_name"
            v-model="companyName"
          />
        </div>
        <div class="mt-2 flex flex-col">
          <label for="account_number" style="">Account Number</label>
          <input
            type="text"
            class="rounded border-gray-200 border p-1 bg-white"
            id="account_number"
            v-model="accountNumber"
          />
        </div>
        <div class="flex justify-end mt-4 w-full">
          <button @click="createCompany()" class="btn-action rounded">Create Company</button>
        </div>
      </div>
    </PopUp>
  </div>
</template>

<script>
import PopUp from '@/components/shared/PopUp';
import DualButton from '@/components/shared/DualButton.vue';
export default {
  props: {
    company: Object,
    user: Object,
  },
  computed: {
    companies() {
      return this.$store.getters['company/getCompanies'];
    },
  },
  data: function () {
    return {
      showCreateCompany: false,
      companyName: null,
      accountNumber: null,
      selectedCompanyId: null,
      buttonOptions: [
        {
          name: 'Assign',
          text: 'Assign user to company.',
        },
        {
          name: 'Create',
          text: 'Create a new company and assign user to the company',
        },
      ],
    };
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Assign':
          this.assignCompany();
          break;

        default:
          this.showCreateCompany = true;
          break;
      }
    },
    assignCompany() {
      if (this.company) {
        //if the user already is assigned to a company, remove that user from the company before
        //adding them to a new company
        this.$store.dispatch('company/removeUser', {
          company_id: this.company._id,
          user_id: this.user._id,
        });
      }
      this.$store.dispatch('company/addUser', {
        company_id: this.selectedCompanyId,
        user_id: this.user._id,
      });
    },
    createCompany() {
      if (!this.companyName || !this.accountNumber) {
        this.$store.commit('setError', {
          message: 'Please enter a company name and account number.',
          color: 'red',
        });
        return;
      }
      this.$store.dispatch('company/createCompany', {
        name: this.companyName,
        accountNumber: this.accountNumber,
        user_id: this.user._id,
      });
      this.showCreateCompany = false;
    },
  },
  components: {
    PopUp,
    DualButton,
  },
};
</script>

<style></style>
